import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOutlineDateRange } from 'react-icons/md';
import logo4 from '../Images/log-4.png';
import main_1 from '../Images/log-11.png';
import main_2 from '../Images/log-12.png';
import main_3 from '../Images/log-13.png';

export default function Approval() {
   

    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(null);
    const formData = JSON.parse(localStorage.getItem('details')) || {};
    const mobileNo = localStorage.getItem('MBN') || '';
    const loanAmount = localStorage.getItem('LoanAmount') || '';
    const [emiDetails, setEmiDetails] = useState([]);
    const [loan_AMT, setLoan_AMT] = useState('');

    
  const moreOptionss = [
    {
        title: 'Money View',
        subTitle: 'Personal Loan',
        logo: main_1,
        loan_amt: loanAmount * 0.8,
        ROI: '18',
        Tenure: '60',
        emiOptions: [
            { months: 12, price: '₹ 41,649' },
            { months: 24, price: '₹ 21,809' },
            { months: 36, price: '₹ 15,215' },
            { months: 48, price: '₹ 12,174' },
            { months: 60, price: '₹ 10,356' },
            { months: 72, price: '₹ 9,279' },
        ]
    },
    {
        title: 'Upwards',
        subTitle: 'Personal Loan',
        logo: main_3,
        loan_amt: loanAmount * 0.8,
        ROI: '22',
        Tenure: '24',
        emiOptions: [
            { months: 12, price: '₹ 48,420' },
            { months: 24, price: '₹ 30,400' },
            { months: 36, price: '₹ 23,091' },
            { months: 48, price: '₹ 18,185' },
            { months: 60, price: '₹ 15,223' },
            { months: 72, price: '₹ 13,232' },
        ]
    },
    {
        title: 'faircent.com',
        subTitle: 'Personal Loan',
        logo: logo4,
        loan_amt: loanAmount * 0.8,
        ROI: '12',
        Tenure: '36',
        emiOptions: [
            { months: 12, price: '₹ 43,139' },
            { months: 24, price: '₹ 22,587' },
            { months: 36, price: '₹ 17,743' },
            { months: 48, price: '₹ 14,451' },
            { months: 60, price: '₹ 12,237' },
            { months: 72, price: '₹ 10,725' },
        ]
    },
    {
        title: 'TATA Capital',
        subTitle: 'Personal Loan',
        logo: main_2,
        loan_amt: loanAmount * 0.8,
        ROI: '10',
        Tenure: '72',
        emiOptions: [
            { months: 12, price: '₹ 42,350' },
            { months: 24, price: '₹ 22,657' },
            { months: 36, price: '₹ 16,754' },
            { months: 48, price: '₹ 13,764' },
            { months: 60, price: '₹ 11,591' },
            { months: 72, price: '₹ 10,221' },
        ]
    }
];

    useEffect(() => {
        const details = moreOptionss.map((data) => {
            const loanAmount = parseFloat(data.loan_amt); // Convert loan amount to number
            const rate = parseFloat(data.ROI.replace(/[^0-9.-]+/g, '')); // Convert ROI to number
            const tenures = [12, 24, 36, 48, 60, 72]; // Define tenures to calculate EMI for
            setLoan_AMT(loanAmount);
            const emiOptions = tenures.map(month => {
                // Monthly interest rate
                const monthlyInterestRate = (rate / 12) / 100;

                // EMI calculation
                const emi = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, month)) 
                          / (Math.pow(1 + monthlyInterestRate, month) - 1);

                return {
                    months: month,
                    price: `₹${emi.toFixed(2)}`
                };
            });

            // Collect details for rendering
            return {
                company: data.title,
                tenure: data.Tenure,
                subtitle: data.subTitle,
                logo: data.logo,
                loanAmount: `₹${loanAmount.toFixed(2)}`,
                rate: `${rate}%`,
                emiOptions
            };
        });

        setEmiDetails(details);
    }, [moreOptionss]);


   

    const emiOptions = [
        { months: 12, price: '41,649' },
        { months: 24, price: '21,809' },
        { months: 36, price: '15,215' },
        { months: 48, price: '11,933' },
        { months: 60, price: '9,975' },
        { months: 72, price: '8,680' },
        
    ];
  

    const handleClick = (option, company) => {
        const selectedCompany = moreOptionss.find(item => item.title === company);
        
        const selectedDetails = { 
            ...option, 
            company, 
            logo: selectedCompany.logo, 
            rate: selectedCompany.ROI 
        };

        setSelectedOption(selectedDetails);
    };


   const handleGetOffer = () => {
        if (selectedOption) {
            console.log(`Selected EMI Option: 
                ${selectedOption.months} Months, 
                Rs. ${selectedOption.price}, 
                Company: ${selectedOption.company}, 
                Rate: ${selectedOption.rate}, 
                Logo: ${selectedOption.logo}`);
            navigate('/membershiporder', { state: { selectedOption } });
        } else {
            alert('Please select an EMI option before proceeding.');
        }
    };
    


useEffect(() => {
    const details = moreOptionss.map((data) => {
      const loanAmount = parseFloat(data.loan_amt); // Convert loan amount to number
      console.log('loanAmount8888 =>',loanAmount);
      const rate = parseFloat(data.ROI.replace(/[^0-9.-]+/g, '')); // Convert ROI to number
      const tenures = [12, 24, 36, 48, 60, 72]; // Define tenures to calculate EMI for

      const emiOptions = tenures.map(month => {
        // Monthly interest rate
        const monthlyInterestRate = (rate / 12) / 100;

        // EMI calculation
        const emi = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, month)) 
                  / (Math.pow(1 + monthlyInterestRate, month) - 1);

        return {
          months: month,
          price: `₹${emi.toFixed(2)}`
        };
      });

      // Collect details for rendering
      return {
        company: data.title,
        tenure: data.Tenure,
        subtitle: data.subTitle,
        logo: data.logo,
        loanAmount: `₹  ${loanAmount * 0.8.toFixed(2)}`,
        rate: `${rate}%`,
        emiOptions
      };
    });

    setEmiDetails(details);
    
    
  }, [moreOptionss]);



    return (<>
   
        <div className="bg-gray-100 flex items-center justify-center">
            <div className="mx-auto w-full p-8 rounded-lg max-w-6xl" >
                <h1 className="text-3xl font-[500] mb-4 text-gray-800">Personal Loan</h1>
                <p className='mb-2 text-md text-gray-800'>Congrats, {formData.fullName}! </p>

                <p className="mb-8 text-md text-gray-800"> YourLoan Amount <span className='font-[600] text-green-600'>₹ {Number(loan_AMT).toLocaleString('en-IN')}/- </span> is Successfully Pre-Approved. Kindly Proceed With you Loan Offer Now! </p>
                {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8"> */}
                <div className="">
                    
                    <div className="p-8 border border-orange-400 rounded-lg bg-white shadow col-span-1">
                    <div className='bg-[#f4f6f9] px-6 py-6'>
                            <h2 className="mb-4 font-[500] tracking-widest"> Your loan amount is successfully approved...!!
                            </h2>
                            <p className="text-2xl sm:text-3xl font-[400] ">₹ {Number(loan_AMT).toLocaleString('en-IN')}/-</p>
                        </div>
                       
                        <p className='pt-8 text-lg text-gray-900'> Customer Details </p>
                        <div className="text-sm mt-2">
                            <p className='py-3 text-[15px] text-gray-600'>Name : <span className='text-gray-900'> {formData.fullName} </span> </p>
                            <p className='py-3 text-[15px] text-gray-600'>Mobile : <span className='text-gray-900'> {mobileNo} </span> </p>
                            <p className='py-3 text-[15px] text-gray-600'>Email : <span className='text-gray-900'> {formData.email} </span></p>
                            <p className='py-3 text-[15px] text-gray-600'>Loan : <span className='text-gray-900'> Personal Loan </span> </p>
                        </div>
                    <hr />
                    <p className='text-center mt-5'>How is pre-approved loan offer calculated? know Here</p>
                    </div>
                    
                    
                </div>
            </div>

        </div>
        <div className="bg-gray-100">
            <div className="container mx-auto p-4">
                <h1 className="text-center text-2xl font-bold mb-4">You’re Eligible For Pre-Approved Loan Offers From Partnered NBFCs</h1>
                <p className="text-center mb-8">View the specifics of your pre-approved offers</p>
             
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10 px-0 sm:px-10">
                {emiDetails.map((option,i) => (
                    <div key={i} className="bg-white shadow-md border border-orange-500 rounded-lg">
                        <div className="flex justify-between mb-2 bg-gray-200 rounded-t-lg p-2">
                            <div>
                                <h2 className="font-bold text-lg text-gray-800 p-2"> {option.company} </h2>
                                <p className="text-lg text-gray-800 pl-2"> {option.subtitle }</p>
                            </div>
                            <img src={option.logo} alt="Logo" className="h-16" />
                        </div>
                        <h2 className="text-lg font-medium mb-4 mt-2 ml-2 sm:ml-12">
                            Select your suitable EMI option:
                        </h2>
                        <div className=" grid grid-cols-3 gap-0 sm:gap-3 rounded-b-lg  text-center">
                            <p className='col-span-2 ml-3 sm:ml-12' style={{ textAlign: 'left'}}><span className='font-[600] mr-1'>Loan Amt.:</span>   ₹ {Number(loan_AMT).toLocaleString('en-IN')}/- </p>
                            <p><span className='font-[600]'>ROI:</span> {option.rate} </p>
                            {/* <p><span className='font-[600]'>EMI:</span> {option.EMI} </p> */}
                            {/* <p><span className='font-[600]'>Tenure:</span> {option.Tenure} </p> */}
                        </div>
                        <div className='px-6'> 
                       
                        <div className="grid grid-cols-3 gap-4 py-5 ">
                        {option.emiOptions.map((data) => (
                                            <div key={data.months}
                                            className={`py-3 px-3 rounded-lg cursor-pointer
                                                ${selectedOption && selectedOption.months === data.months && selectedOption.company === option.company
                                                    ? "bg-green-100 border border-green-500"
                                                    : "bg-white border border-gray-300"
                                                }`}
                                            onClick={() => handleClick(data, option.company)}
                                        >
                                    <div className='flex gap-4 justify-center items-center '>
                                        <MdOutlineDateRange size={20} className='hidden sm:block'/>
                                        <p className='font-[600] text-gray-800'>{data.months} Month</p>
                                    </div>
                                    <p className="font-[500] text-center text-gray-700 py-1">{data.price}</p>
                                </div>
                            ))}
                        </div>
                         <div className="flex justify-center mt-2 mb-10" >
                            <button
                                className="text-white py-2 px-4 rounded-lg w-[90%] sm:w-[50%] buynow"
                                onClick={handleGetOffer}
                            >
                                GET OFFER
                            </button>
                        </div>
                        </div>

                        <p className="bg-gray-200 text-gray-800 font-[600] p-2 rounded-b-lg text-[12px]">
                            Offer From Our Partnered NBFC – Valid Till 12:00 AM Only
                        </p>
                    </div>
                ))}
                 </div> 
                <p className='py-8 px-0 sm:px-10'>Disclaimer - The above data is tentative and purely on the information provided by you. Final EMI, loan sanction, 
                loan approval, and loan amount depend on customer profile and NBFCs criteria and rules & regulations.</p>
            </div>

        </div>
    </>);
}
