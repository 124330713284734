import React from 'react';
import logo from '../Images/loanlogo1.png';

export default function Header() {
  return <>
    <header id="header" data-transparent="true" data-fullwidth="true" className="submenu-light header-disable-fixed" 
    style={{background:'#fef2ea'}}>
      <div className="header-inner">
        <div className="container">

          <div id="logo"> 
            <a href="#">
              <span className="logo-default mt-7 ml-5"><img src={logo} alt="Nowof Loan" width="150" /></span>
            </a> 
          </div>

          <div className="header-extras">
            <div className="p-dropdown">
                <a className="x"><span className="lines"></span></a>
                <ul className="p-dropdown-content">
                    <li><a href="tel:+91-74860-19373"><i className="icon-phone-call"></i>+91-74860-19373</a></li>
                                        <li><a href="mailto:info@cashnowloan.shop"><i className="icon-mail"></i>info@cashnowloan.shop</a></li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </header>	
  </>
}
