import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


export default function CheckDetails() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const formData = JSON.parse(localStorage.getItem('details')) || {};
    const mobileNo = localStorage.getItem('MBN') || '';
    const loanAmount = localStorage.getItem('LoanAmount') || '';




    console.log('formData =>', formData);
    console.log('mobileNo =>', mobileNo);

    const [formDetails, setFormDetails] = useState({
        cibil: '',
        monthlyEMI: '',
        city: '',
        monthlyIncome: '',
        loanPurpose: '',
        state: '',
    });

    //  useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setLoading(true);
    //     }, 5000); // 5 seconds delay

    //     return () => clearTimeout(timer); // Cleanup timer on component unmount
    // }, []);

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormDetails({
            ...formDetails,
            [e.target.name]: e.target.value,
        });
    };

    const validateForm = () => {
        const newErrors = {};

        // Validation logic
        if (!formDetails.cibil || formDetails.cibil === 'Select Score') {
            newErrors.cibil = 'Please select your CIBIL score';
        }
        if (!formDetails.monthlyEMI) {
            newErrors.monthlyEMI = 'Please enter your current monthly EMI';
        }
        if (!formDetails.city) {
            newErrors.city = 'Please enter your city';
        }
        if (!formDetails.monthlyIncome) {
            newErrors.monthlyIncome = 'Please enter your monthly income';
        }
        if (!formDetails.loanPurpose || formDetails.loanPurpose === '') {
            newErrors.loanPurpose = 'Please select the loan purpose';
        }
        if (!formDetails.state || formDetails.state === '') {
            newErrors.state = 'Please select your state';
        }

        return newErrors;
    };

   const handleSubmit = (e) => {
        e.preventDefault();

        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors); // Set errors if there are validation errors
        } else {
            setErrors({}); // Clear errors
            localStorage.setItem('CheckDt', JSON.stringify(formDetails));
            
            // Show loading spinner and navigate after 5 seconds
            setLoading(true);
            setTimeout(() => {
                navigate('/preapproval'); // Redirect after 5 seconds
            }, 5000); // 5000 milliseconds = 5 seconds
        }
    };

    return (
        <>
        {loading && (
           <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
           <div className="relative flex items-center justify-center w-20 h-20">
               <div className="absolute w-20 h-20 border-8 border-gray-300 border-t-transparent border-solid rounded-full animate-spin"></div>
               <div className="absolute w-16 h-16 border-8 border-gray-200 border-t-blue-500 border-solid rounded-full animate-spin"></div>
               <div className="absolute w-12 h-12 border-8 border-gray-100 border-t-orange-500 border-solid rounded-full animate-spin"></div>
           </div>
       </div>
        )}
          <div className="bg-gray-100 flex items-center justify-center">
             <div className="mx-auto w-full p-8 rounded-lg max-w-6xl" >
                 <h1 className="text-3xl font-[500] mb-4 text-gray-800">Personal Loan</h1>
                 <p className="mb-8 text-xl text-gray-800">Fill Your Details To <span className='font-[600]'>Get Pre-Approved Loan Offer</span> From Our NBFC Partners</p>
                 <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8">
                     <div className="p-8 border border-orange-400 rounded-lg bg-white shadow col-span-1">
                         <div className='bg-[#f4f6f9] px-6 py-6'>
                             <h2 className="mb-4 font-[500] tracking-widest">Desired Loan Amount</h2>
                             <p className="text-2xl sm:text-3xl font-[400] ">₹ {Number(loanAmount).toLocaleString('en-IN')}/-</p>
                         </div>
                         <p className='pt-8 text-lg text-gray-900'> Customer Details </p>
                         <div className="text-sm mt-2">
                             <p className='py-3 text-[15px] text-gray-600'>Name : <span className='text-gray-900'> {formData.fullName} </span> </p>
                             <p className='py-3 text-[15px] text-gray-600'>Mobile : <span className='text-gray-900'> {mobileNo} </span> </p>
                             <p className='py-3 text-[15px] text-gray-600'>Email : <span className='text-gray-900'> {formData.email} </span></p>
                             <p className='py-3 text-[15px] text-gray-600'>Loan : <span className='text-gray-900'> Personal Loan </span> </p>
                         </div>
                     </div>
                    
                 <form onSubmit={handleSubmit} className="p-4 mt-9 sm:mt-0 border border-orange-400 rounded-lg bg-white shadow col-span-1 sm:col-span-2">
                 <div className='grid grid-cols-1 sm:grid-cols-2'>
                     <div className='py-2 px-5'>
                         <label className="block text-[#222] text-md font-medium mb-1" htmlFor="cibil">
                             CIBIL Score
                         </label>
                         <div className="relative">
                             <select
                                 name="cibil"
                                 id="cibil"
                                 value={formDetails.cibil}
                                 onChange={handleChange}
                                 className="block w-full border-2 border-gray-300 rounded-lg py-2 px-3 pr-10 mb-4 bg-white text-gray-700 focus:outline-none appearance-none"
                                 style={{ fontSize: '14px' }}
                             >
                                 <option value="Select Score">Select Score</option>
                                 <option value="Below 650">Below 650</option>
                                 <option value="650-700">650-700</option>
                                 <option value="700-750">700-750</option>
                                 <option value="750-800">750-800</option>  
                                 <option value="800-850">800-850</option>
                                 <option value="850-900">850-900</option>
                             </select>
                             <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                 <svg className="fill-current h-4 w-4" xmlns="http:www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" /></svg>
                             </div>

                         </div>
                         {errors.cibil && <p className="text-red-500 text-sm pb-2">{errors.cibil}</p>}

                         <div>
                             <label className="block text-[#222] text-md font-medium mb-1" htmlFor="monthlyEMI">
                                 Current Monthly EMI
                             </label>
                             <input
                                 type="text"
                                 name="monthlyEMI"
                                 id="monthlyEMI"
                                 value={formDetails.monthlyEMI}
                                 onChange={handleChange}
                                 className="flex-grow border-2 rounded-lg py-2 px-3 mb-4 w-full focus:outline-none border-2 border-gray-300"
                                 placeholder="As per your bank records"
                                 style={{ fontSize: '14px' }}
                             />
                         {errors.monthlyEMI && <p className="text-red-500 text-sm pb-2">{errors.monthlyEMI}</p>}

                         </div>
                         <div>
                             <label className="block text-[#222] text-md font-medium mb-1" htmlFor="city">
                                 City
                             </label>
                             <input
                                 type="text"
                                 name="city"
                                 id="city"
                                 value={formDetails.city}
                                 onChange={handleChange}
                                 className="flex-grow border-2 rounded-lg py-2 px-3 mb-4 w-full focus:outline-none border-2 border-gray-300"
                                 placeholder="As per your bank records"
                                 style={{ fontSize: '14px' }}
                             />
                         {errors.city && <p className="text-red-500 text-sm pb-2">{errors.city}</p>}

                         </div>
                     </div>
                     <div className='px-5 py-3'>
                         <div>
                             <label className="block text-[#222] text-sm font-medium mb-1" htmlFor="monthlyIncome">
                                 Monthly Income
                             </label>
                             <input
                                 type="text"
                                 name="monthlyIncome"
                                 id="monthlyIncome"
                                 value={formDetails.monthlyIncome}
                                 onChange={handleChange}
                                 className="flex-grow border-2 rounded-lg py-2 px-3 mb-4 w-full focus:outline-none border-2 border-gray-300"
                                 placeholder="As per your bank records"
                                 style={{ fontSize: '14px' }}
                             />
                         {errors.monthlyIncome && <p className="text-red-500 text-sm pb-2">{errors.monthlyIncome}</p>}

                         </div>
                         <div>
                             <label className="block text-[#222] text-md font-medium mb-1" htmlFor="loanPurpose">
                                 Loan Purpose
                             </label>
                             <div className="relative">
                                 <select
                                     name="loanPurpose"
                                     id="loanPurpose"
                                     value={formDetails.loanPurpose}
                                     onChange={handleChange}
                                     className="block w-full border-2 border-gray-300  rounded-lg py-2 px-3 pr-10 mb-4 bg-white text-gray-700 focus:outline-none appearance-none"
                                     style={{ fontSize: '14px' }}
                                 >
                                     <option value="">Select Loan Purpose</option>
                                     <option value="Personal Use">Personal Use</option>
                                     <option value="Property Renovation">Property Renovation</option>
                                     <option value="Marriage Purpose">Marriage Purpose</option>
                                     <option value="Education Purpose">Education Purpose</option>
                                     <option value="Medical Emergency">Medical Emergency</option>
                                     <option value="Other">Other</option>
                                 </select>

                                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                     <svg className="fill-current h-4 w-4" xmlns="http:www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" /></svg>
                                 </div>
                             </div>
                         {errors.loanPurpose && <p className="text-red-500 text-sm pb-2">{errors.loanPurpose}</p>}

                         </div>
                         <div>
                             <label className="block text-[#222] text-md font-medium mb-1" htmlFor="state">
                                 State
                             </label>
                             <div className="relative">
                                 <select
                                     name="state"
                                     id="state"
                                     value={formDetails.state}
                                     onChange={handleChange}
                                     className="block w-full border-2 border-gray-300 rounded-lg py-2 px-3 pr-10 mb-4 bg-white text-gray-700 focus:outline-none appearance-none"
                                     style={{ fontSize: '14px' }}
                                 >
                                     <option value="">Select State</option>
                                     <option value="Andhra Pradesh">Andhra Pradesh</option>
                                     <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                     <option value="Assam">Assam</option>
                                     <option value="Bihar">Bihar</option>
                                     <option value="Chhattisgarh">Chhattisgarh</option>
                                     <option value="Goa">Goa</option>
                                     <option value="Gujarat">Gujarat</option>
                                     <option value="Haryana">Haryana</option>
                                     <option value="Himachal Pradesh">Himachal Pradesh</option>
                                     <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                     <option value="Jharkhand">Jharkhand</option>
                                     <option value="Karnataka">Karnataka</option>
                                     <option value="Kerala">Kerala</option>
                                     <option value="Madhya Pradesh">Madhya Pradesh</option>
                                     <option value="Maharashtra">Maharashtra</option>
                                     <option value="Manipur">Manipur</option>
                                     <option value="Meghalaya">Meghalaya</option>
                                     <option value="Mizoram">Mizoram</option>
                                     <option value="Nagaland">Nagaland</option>
                                     <option value="Odisha">Odisha</option>
                                     <option value="Punjab">Punjab</option>
                                     <option value="Rajasthan">Rajasthan</option>
                                     <option value="Sikkim">Sikkim</option>
                                     <option value="Tamil Nadu">Tamil Nadu</option>
                                     <option value="Telangana">Telangana</option>
                                     <option value="Tripura">Tripura</option>
                                     <option value="Uttar Pradesh">Uttar Pradesh</option>
                                     <option value="Uttarakhand">Uttarakhand</option>
                                     <option value="West Bengal">West Bengal</option>
                                     <option value="Other">Other</option>
                                 </select>

                                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                     <svg className="fill-current h-4 w-4" xmlns="http:www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" /></svg>
                                 </div>
                             </div>
                         {errors.state && <p className="text-red-500 text-sm pb-2">{errors.state}</p>}

                         </div>
                     </div>
                 </div>
                 <div className="flex justify-center mt-4">
                     <button
                         type="submit"
                         className="py-3 px-8 font-medium text-white bg-[#ef8a09] rounded-xl  buynow-mebership"
                     >
                         CHECK ELIGIBILITY
                     </button>
                 </div>
             </form>
       
                 </div>
             </div>
            
         </div>
    </>
      
    );
}
