import React from 'react'

export default function Footer() {
  return (
    <div>
         <div className="bg-[#4d5154] text-white py-5 px-10 text-center text-sm flex">
        <p className='mr-auto'> 2024 © Nowofin Service India Pvt. Ltd. All rights reserved.</p> 
        <p className='ml-auto'> CIN No: U93090GJ2019PTC109257 </p>
    </div>
    </div>
  )
}
