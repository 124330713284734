import React, { useState , useEffect} from 'react';
import { FaPlus } from "react-icons/fa6";
import { FiMinus } from 'react-icons/fi';
import { HiOutlineBadgeCheck } from "react-icons/hi";
import log1 from '../Images/log-1.png';
import log2 from '../Images/log-2.png';
import log3 from '../Images/log-3.jpeg';
import log4 from '../Images/log-4.png';
import log5 from '../Images/log-5.png';
import log6 from '../Images/log-6.png';
import log7 from '../Images/log-7.jpg';
import log8 from '../Images/log-8.png';
import log9 from '../Images/log-9.png';
import log10 from '../Images/log-10.png';


import { json, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

export default function Home() {
    const [mobile, setMobile] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [formDetails, setFormDetails] = useState({
        loanamount: '50000',  // Default value set to "50,000"
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormDetails({
            ...formDetails,
            [name]: value  // Update state with the selected value
        });
        console.log("Selected Loan Amount: Rs.", value); // Logs the selected value to the console
    };
    const navigate = useNavigate();

  
    const handleSubmit = (e) => {
        e.preventDefault();
    
        // Mobile number validation
        if (mobile.length !== 10 || isNaN(mobile)) {
            setErrorMessage('Please enter a valid 10-digit mobile number.');
            return;
        }
    
        // Parse loan amount to ensure it's a number
        const loanAmount = parseFloat(formDetails.loanamount);
        if (isNaN(loanAmount)) {
            setErrorMessage('Please select a valid loan amount.');
            return;
        }
        console.log(loanAmount);
    
        // Calculate 80% of the loan amount
        // const calculatedAmount = loanAmount * 0.8;
    
        // // Log the mobile number and calculated 80% loan amount to the console
        // console.log('Mobile:', mobile);
        // console.log('Loan Amount (80%):', calculatedAmount);
    
        // If valid, proceed to navigate or handle form submission
        setErrorMessage('');
    
        // Store the mobile number and 80% of loan amount in localStorage
        localStorage.setItem('MBN', JSON.parse(mobile));
        localStorage.setItem('LoanAmount', JSON.stringify(loanAmount));
    
        // Navigate to another component or pass the value
        navigate('/details'); // Uncomment if you're using routing
    };
    
    useEffect(() => {
        // Clear all localStorage data when the component mounts
        localStorage.clear();
        console.log("LocalStorage cleared on component load");
    }, []); 

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,

    };


    return (
        <>

            <div className='bg-custom-image'>
                <div className="flex items-center justify-center  p-4 sm:p-6 lg:p-8 bg-custom-image">
                    <form
                        className="bg-white p-5 sm:p-7 lg:p-9 rounded max-w-2xl w-full shadow-md my-14"
                        onSubmit={handleSubmit}
                    >
                        <input type="hidden" name="loantype" id="loantype" value="11" required />

                        <h3 className="text-2xl sm:text-2xl lg:text-3xl font-bold mb-4 leading-normal">
                            Apply For <span className="text-[#ef8a09]">Rs.5 Lakhs</span> Personal Loan in Minutes!
                        </h3>

                        <div className="mb-4 " style={{ lineHeight: '0px', marginTop: '31px' }}>
                            <label className="block text-gray-900 ml-1 text-md sm:text-lg font-medium mb-2 sm:mb-0 tracking-wide" htmlFor="setloanamount">
                                Enter Your Required Amount: 
                            </label>
                            <div className="relative mt-3">
                                <select
                                    name="loanamount"
                                    id="loanamount"
                                    value={formDetails.loanamount}  // Bind selected value to state
                                    onChange={handleChange}  // Handle selection changes
                                    className="block w-full border-2 border-gray-300 rounded-lg py-2 px-3 pr-10 mb-4"
                                    style={{ fontSize: '14px' }}
                                >
                                    {/* Default option is set to "50,000" */}
                                    <option value="50000">50,000</option>
                                    <option value="100000">1,00,000</option>
                                    <option value="200000">2,00,000</option>
                                    <option value="300000">3,00,000</option>
                                    <option value="400000">4,00,000</option>
                                    <option value="500000">5,00,000</option>
                                </select>

                                {/* Arrow icon */}
                                {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" />
                    </svg>
                </div> */}
                            </div>

                        </div>


                        <div className="mb-4 mt-5">
                            <label className="block text-black text-lg sm:text-[14px] font-medium mb-2" htmlFor="mobileno">
                                Mobile no.
                            </label>
                            <div className="flex">
                                <span className="flex items-center px-2 border border-black border-r-0 rounded-l bg-[#222] text-gray-200">+91</span>
                                <input
                                    type="text"
                                    name="mobile"
                                    id="mobileno"
                                    className="flex-grow border-2 border-l-0 rounded-r py-2 px-3 focus:outline-none"
                                    placeholder="Bank registered number"
                                    inputMode="numeric"
                                    style={{ fontSize: '14px' }}
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                />
                            </div>
                            {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
                        </div>

                        <div className="mb-11 mt-11 sm:mt-6">
                            <button
                                type="submit"
                                id="form-submit1"
                                className="w-full bg-[#ef8a09] text-white font-bold py-[10px] px-[13px] rounded "
                            >
                                APPLY NOW
                            </button>
                        </div>

                        <div className="mb-4 text-left">
                            <h5 className="text-md flex items-center mb-5">
                                <HiOutlineBadgeCheck size={35} className='mr-1 mt-[2px]' />
                                <span>Get Your <span className="font-semibold tracking-wider">Personal Loan in just 3 Steps</span> from Our NBFC/Lending Partners:</span>
                            </h5>
                            <div className="mt-10">
                                <Slider {...sliderSettings}>
                                    <div>
                                        <img src={log1} alt="Faircent.com" className="h-14 w-auto mx-auto" />
                                    </div>
                                    <div>
                                        <img src={log2} alt="IIFL" className="h-14 w-auto mx-auto" />
                                    </div>
                                    <div>
                                        <img src={log3} alt="prefr" className="h-14 w-auto mx-auto" />
                                    </div>
                                    <div>
                                        <img src={log4} alt="prefr" className="h-14 w-auto mx-auto" />
                                    </div><div>
                                        <img src={log5} alt="prefr" className="h-14 w-auto mx-auto" />
                                    </div><div>
                                        <img src={log6} alt="prefr" className="h-14 w-auto mx-auto" />
                                    </div><div>
                                        <img src={log7} alt="prefr" className="h-14 w-auto mx-auto" />
                                    </div><div>
                                        <img src={log8} alt="prefr" className="h-14 w-auto mx-auto" />
                                    </div><div>
                                        <img src={log9} alt="prefr" className="h-14 w-auto mx-auto" />
                                    </div>
                                    <div>
                                        <img src={log10} alt="prefr" className="h-14 w-auto mx-auto" />
                                    </div>
                                    {/* Add more slides here if needed */}
                                </Slider>
                            </div>
                        </div>

                        <div className="text-left text-gray-500 text-sm mt-4">
                            <hr className="mb-2" />
                            <small>
                                By submitting the form & proceeding, you agree to the{' '}
                                <a href="https://cashnowloan.shop/terms-conditions" target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                    Terms of Use
                                </a>{' '}
                                and{' '}
                                <a href="https://cashnowloan.shop/privacy-policy" target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                    Privacy Policy
                                </a>{' '}
                                of cashnowloan.shop
                            </small>
                        </div>
                    </form>
                </div>

                <div className="bg-[#ebf1fb] m-0 p-0 font-sans mt-10">
                    <div className="bg-[#ebf1fb] px-4 sm:px-10 py-4 sm:py-8">
                        <p className="m-0 text-sm text-gray-800 leading-relaxed" style={{ fontFamily: 'poppins, sans-serif' }}>
                            Loan tenure ranging up to 72 months with Annual Interest Rates ranging between 12.5% - 35%. Processing fee up to 2%. For Example: Considering a personal loan of Rs.1,00,000 availed at 12.5%* interest rate for a tenure of 6* years with 2%* processing fee, the APR will be 13.27%*. *T&C Apply. All these numbers are tentative/indicative, the final loan specifics may vary depending upon the customer profile and NBFCs’ criteria, rules & regulations, and terms & conditions. Company Registered Address : C-301, 3rd Floor, Heny Arcade, Dabholi Road, Surat-395004
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}
