import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus } from "react-icons/fa6";
import { FaEquals } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa";
import { useLocation } from 'react-router-dom';



export default function MemberShipOrder() {
    const navigate = useNavigate();
    const formData = JSON.parse(localStorage.getItem('details')) || {};
    const mobileNo = localStorage.getItem('MBN') || '';
    const LoanAmount = localStorage.getItem('LoanAmount') || '';

    const location = useLocation();
    const { selectedOption } = location.state || {};
console.log('selectedOption =>',selectedOption);

    const openGpay = (price) => {
        // if (price) {
        //   if (!window.PaymentRequest) {
        //     alert('Web payments are not supported in this browser.');
        //     return;
        //   }

        //   // Create supported payment method.
        //   const supportedInstruments = [
        //     {
        //       supportedMethods: ['https://tez.google.com/pay'],
        //       data: {
        //         pa: 'mab.037324013060061@axisbank',  // Replace with your Merchant UPI ID
        //         pn: 'Merchant Name',  // Replace with your Merchant Name
        //         tr: '1234ABCD',  // Your custom transaction reference ID
        //         url: 'https://yourwebsite.com/order/1234ABCD',  // URL of the order in your website
        //         mc: '1234', // Your merchant category code
        //       },
        //     }
        //   ];

        //   // Create order detail data.
        //   const details = {
        //     total: {
        //       label: 'Total',
        //       amount: {
        //         currency: 'INR',
        //         value: price, // Amount to be paid
        //       },
        //     },
        //     displayItems: [{
        //       label: 'Original Amount',
        //       amount: {
        //         currency: 'INR',
        //         value: price,
        //       },
        //     }],
        //   };

        //   // Create payment request object.
        //   let request = null;
        //   try {
        //     request = new PaymentRequest(supportedInstruments, details);
        //   } catch (e) {
        //     alert('Payment Request Error: ' + e.message);
        //     return;
        //   }
        //   if (!request) {
        //     alert('Web payments are not supported in this browser.');
        //     return;
        //   }

        //   var canMakePaymentPromise = checkCanMakePayment(request);
        //   canMakePaymentPromise
        //     .then((result) => {
        //       showPaymentUI(request, result);
        //     })
        //     .catch((err) => {
        //       alert('Error calling checkCanMakePayment: ' + err);
        //     });
        // }

        // function checkCanMakePayment(request) {
        //   // Checks canMakePayment cache, and use the cache result if it exists.
        //   const canMakePaymentCache = 'canMakePaymentCache';

        //   if (sessionStorage.hasOwnProperty(canMakePaymentCache)) {
        //     return Promise.resolve(JSON.parse(sessionStorage[canMakePaymentCache]));
        //   }

        //   // If canMakePayment() isn't available, default to assuming that the method is supported.
        //   var canMakePaymentPromise = Promise.resolve(true);

        //   // Feature detect canMakePayment().
        //   if (request.canMakePayment) {
        //     canMakePaymentPromise = request.canMakePayment();
        //   }

        //   return canMakePaymentPromise
        //     .then((result) => {
        //       // Store the result in cache for future usage.
        //       sessionStorage[canMakePaymentCache] = result;
        //       return result;
        //     })
        //     .catch((err) => {
        //       alert('Error calling canMakePayment: ' + err);
        //     });
        // }

        // function showPaymentUI(request, canMakePayment) {
        //   if (false) {
        //     alert('Google Pay is not ready to pay.');
        //     return;
        //   }

        //   // Set payment timeout.
        //   let paymentTimeout = window.setTimeout(function () {
        //     window.clearTimeout(paymentTimeout);
        //     request.abort()
        //       .then(function () {
        //         alert('Payment timed out.');
        //       })
        //       .catch(function () {
        //       });
        //   }, 20 * 60 * 1000); /* 20 minutes */

        //   request.show()
        //     .then(function (instrument) {
        //       window.clearTimeout(paymentTimeout);
        //     //   processResponse(instrument); // Handle response from browser.
        //     })
        //     .catch(function (err) {
        //       alert(err);
        //     });
        // }

        const rowurl = `amount=${price}`;

        function encryptURL(rowurl) {
            var encryptedUrl = '';
            for (var i = 0; i < rowurl.length; i++) {
                var encryptedChar = String.fromCharCode(rowurl.charCodeAt(i) + 1);
                encryptedUrl += encryptedChar;
            }
            return encryptedUrl;
        }

        // Encrypt the URL
        var encryptedUrl = encryptURL(rowurl);
        console.log("Encrypted URL: " + encryptedUrl);

        const URL = `https://astropandit.store/payment.html?q=${encryptedUrl}`;
        window.location.href = URL;
    }

    return (<>

        <div className="bg-gray-100 flex items-center justify-center">
            <div className="mx-auto w-full p-8 rounded-lg max-w-[80rem]" >
                <h1 className="text-3xl font-[500] mb-4 text-gray-800">Personal Loan</h1>
                <p className='mb-2 text-md text-gray-800'>Congrats, {formData.fullName}! </p>


                <p className="mb-8 text-md text-gray-800"> Buy Membership To Instantly Process Your <span className='font-[600] text-green-600'> Rs.{Number(LoanAmount).toLocaleString('en-IN')}/- Pre-Approved Loan  </span> Offer. <span className='text-red-500 font-[600]'>  - Valid Till 12;00 AM </span> </p>
                {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 lg:gap-8"> */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 ">

                    <div className="p-8 border border-orange-400 rounded-lg bg-white shadow col-span-1">
                    <div className="bg-gradient-to-r from-[#bbbec4] to-[#969494] rounded-lg shadow-lg p-8">
    <h2 className="mb-6 text-xl font-semibold tracking-wider text-gray-700">Desired Loan Amount</h2>
    <p className="text-2xl sm:text-2xl font-bold text-[#2d3748]">₹ {Number(LoanAmount).toLocaleString('en-IN')} /-</p>
    
   
        <div className="mt-6 bg-white py-1 px-3 rounded-lg shadow-md transition-transform transform ">
            <div className=" items-center mb-4">
                <img src={selectedOption.logo} alt="Company Logo" className="w-[50%] h-[50%] " />
                <div className="">
                    <p className="text-lg font-medium text-gray-600">{selectedOption.company} Company</p>
                    <p className="text-sm text-gray-500">{selectedOption.rate}% Interest Rate</p>
                </div>
            </div>
            <div className="flex justify-between items-center">
                <p className="text-lg font-medium text-gray-700">{selectedOption.month} Months</p>
                <p className="text-lg font-medium text-gray-700"> {selectedOption.price}</p>
            </div>
        </div>
  
</div>

                        <p className='pt-8 text-lg text-gray-900'> Customer Details </p>
                        <div className="text-sm mt-2">
                            <p className='py-3 text-[15px] text-gray-600'>Name : <span className='text-gray-900'> {formData.fullName} </span> </p>
                            <p className='py-3 text-[15px] text-gray-600'>Mobile : <span className='text-gray-900'> {mobileNo} </span> </p>
                            <p className='py-3 text-[15px] text-gray-600'>Email : <span className='text-gray-900'> {formData.email} </span></p>
                            <p className='py-3 text-[15px] text-gray-600'>Loan : <span className='text-gray-900'> Personal Loan </span> </p>
                        </div>
                    </div>

                    <div className="bg-white p-4 sm:p-8 border border-orange-300 rounded-lg shadow col-span-2 ml-0 mt-5 sm:mt-0 sm:ml-5 text-center">
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                            <div className="border border-gray-300 p-5 text-center">
                                <h1 className="text-2xl font-[500]">Premium Membership</h1>
                                <div className=" items-center justify-center  my-5">
                                    <p className="text-red-600 line-through  font-[500] text-2xl"> Rs.1,999/- </p>

                                    <p className="text-green-600 text-4xl font-[600] my-3 ">Rs.499/-</p>
                                    <p className="text-[#000] text-lg font-[500] ">75% Off</p>

                                </div>
                                <div className="">
                                    <hr className="my-2 w-52 mx-auto" />
                                    <div className="flex items-center justify-center my-2">
                                        <div className="text-center">
                                            <div className="flex ">
                                                <FaPlus className="mt-1" />
                                                <p>Amount: 409.00 </p>
                                            </div>
                                            <hr className="my-2 w-52 mx-auto" />
                                            <div className="flex  mt-2 ">
                                                <FaPlus className="mt-1" />
                                                <p >GST (18%): 89.82 </p>
                                            </div>
                                            <hr className="my-2 w-52 mx-auto" />
                                            <div className="flex  mt-2 ">
                                                <FaEquals className="mt-1" />
                                                <p >Grand Total: 499.00 </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="text-white py-2 px-10 rounded mt-4 w-full buynow-mebership" onClick={() => openGpay(499)}>
                                    BUY NOW
                                </button>
                            </div>

                            <div className="border border-gray-300 py-5">
                                <div>
                                    <h2 className="font-medium mb-2">Plan Benefits:</h2>
                                    <ul className="list-disc pl-5 space-y-2">
                                        <li className="flex gap-3">
                                            <FaCheck className="mt-1" />
                                            <p>Loan Process in Multiple NBFCs</p>
                                        </li>
                                        <li className="flex gap-3">
                                            <FaCheck className="mt-1" />
                                            <p>100% Online Financial Consultation</p>
                                        </li>
                                        <li className="flex gap-3">
                                            <FaCheck className="mt-1" />
                                            <p>Access Personalized Tracking Portal</p>
                                        </li>
                                        <li className="flex gap-3">
                                            <FaCheck className="mt-1" />
                                            <p>Dedicated Loan Expert Assigned</p>
                                        </li>
                                        <li className="flex gap-3">
                                            <FaCheck className="mt-1" />
                                            <p>No Impact On CIBIL</p>
                                        </li>
                                        <li className="flex gap-3">
                                            <FaCheck className="mt-1" />
                                            <p>Plan Validity: 3 Months</p>
                                        </li>
                                        <li className="flex gap-3">
                                            <FaCheck className="mt-1" />
                                            <p>Loan Processing Time: 48 Hours</p>
                                        </li>
                                    </ul>
                                </div>
                                <hr className="my-4" />
                                <div className="flex justify-between px-4">
                                    <div>
                                        <h1 className="text-xl font-[500]">225k</h1>
                                        <p className="text-sm py-2">Satisfied Customers</p>
                                    </div>
                                    <div>
                                        <h1 className="text-xl font-[500]">100M</h1>
                                        <p className="text-sm py-2">Loan Amt. Disbursed</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}
