import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Your web app's Firebase configuration
const firebaseConfig = {
     apiKey: "AIzaSyA2g1QkDBbyQf0EUWW_2gz_m7x86CywALY",
    authDomain: "loan-notify.firebaseapp.com",
    projectId: "loan-notify",
    storageBucket: "loan-notify.appspot.com",
    messagingSenderId: "199684326553",
    appId: "1:199684326553:web:f8784a55594b9b53aea408",
    measurementId: "G-X4DXPH8W6B"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, getToken, onMessage };
