import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaRegFlag } from "react-icons/fa";
import Bag from '../Images/bag.png';

export default function Details() {
    const location = useLocation();
    const data = location.state; // Data passed during navigation
    const navigate = useNavigate();

    // State for form fields
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        profileType: 'Salaried'
    });

    // State for form errors
    const [errors, setErrors] = useState({});

    // State for mobile number from localStorage
    const [mobileNumber, setMobileNumber] = useState('');
    const [loanAmount, setloanAmount] = useState('');


    // Get mobile number from localStorage on component mount
    useEffect(() => {
        const mobileFromStorage = localStorage.getItem('MBN');
        const loanAmount = localStorage.getItem('LoanAmount');

        if (mobileFromStorage) {
            setMobileNumber(JSON.parse(mobileFromStorage));
        }
        if (loanAmount) {
            setloanAmount(JSON.parse(loanAmount));
        }
    }, []);

    // Handle input change
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    // Handle profile type selection
    const handleProfileTypeChange = (type) => {
        setFormData({
            ...formData,
            profileType: type
        });
    };

    // Validate form
    const validateForm = () => {
        let formErrors = {};

        if (!formData.fullName) {
            formErrors.fullName = "Full name is required.";
        }

        if (!formData.email) {
            formErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formErrors.email = "Email address is invalid.";
        }

        setErrors(formErrors);

        return Object.keys(formErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('data =>', data);

        if (validateForm()) {
            // Navigate to another page and pass form datal
            localStorage.setItem('details', JSON.stringify(formData))
            navigate('/check-details', {
                state: {
                    formData: formData,
                    mobileNo: mobileNumber // Use the mobile number from localStorage
                }
            });
        }
    };

    return (
        <div className='bg-custom-image overflow-x-hidden overflow-y-hidden'>
            <div className="flex items-center justify-center p-4 sm:p-6 lg:p-8 bg-custom-image">
                <form
                    onSubmit={handleSubmit}
                    className="bg-white p-5 sm:p-7 lg:p-9 rounded max-w-2xl w-full shadow-md my-14"
                >
                    <h1 className="text-xl font-[500] mb-4">Select Your Profile & Enter Details.</h1>
                    <h2 className="text-md mb-2 py-1">Loan Amount: {Number(loanAmount).toLocaleString('en-IN')}/-</h2>

                    <h2 className="text-md mb-4 py-1">Mobile No.: {mobileNumber}</h2> {/* Display mobile number from localStorage */}

                    <div className='flex gap-2 mb-4'>
                        <div
                            className={`px-5 py-2 rounded-lg ${formData.profileType === 'Salaried' ? 'bg-[#ef8a09] text-white' : 'bg-gray-200 text-gray-700'} border flex gap-2`}
                            onClick={() => handleProfileTypeChange('Salaried')}
                        >
                            <img src={Bag} className='h-5 w-5 ' style={{ marginTop: '2px' }} alt="Bag Icon" />
                            <button type="button">Salaried</button>
                        </div>

                        <div
                            className={`px-5 py-2 rounded-lg ${formData.profileType === 'Self Employed' ? 'bg-[#ef8a09] text-white' : 'bg-gray-200 text-gray-700'} border flex gap-2`}
                            onClick={() => handleProfileTypeChange('Self Employed')}
                        >
                            <FaRegFlag className='mt-1' />
                            <button type="button">Self Employed</button>
                        </div>
                    </div>

                    <label className="block text-[#222] text-sm font-medium mb-1" htmlFor="fullName">
                        Full name
                    </label>
                    <input
                        type="text"
                        id="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        className="flex-grow border-2 rounded-xl py-2 px-3 mb-4 w-full focus:outline-none"
                        placeholder="Bank registered name"
                        style={{ fontSize: '14px' }}
                    />
                    {errors.fullName && <p className="text-red-500 text-sm">{errors.fullName}</p>}

                    <label className="block text-[#222] text-sm font-medium mb-1" htmlFor="email">
                        Email id
                    </label>
                    <input
                        type="text"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="flex-grow border-2 rounded-xl py-2 px-3 mb-4 w-full focus:outline-none"
                        placeholder="As per your bank records"
                        style={{ fontSize: '14px' }}
                    />
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}

                    <button
                        type="submit"
                        className="w-full bg-[#ef8a09] uppercase text-white font-bold py-3 px-4 rounded-xl"
                    >
                        Process
                    </button>
                </form>
            </div>
        </div>
    );
}
